import React, { useState } from "react";
import axios from "axios";
import "./reviewform.scss";

const ReviewForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    review: "",
    image: null,
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("review", formData.review);
    if (formData.image) {
      formDataToSend.append("image", formData.image);
    }

    try {
      const response = await axios.post(
        "https://meenakari-backend.onrender.com/api/reviews",
        formDataToSend,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (response.status === 200 || response.status === 201) {
        console.log("✅ Review submitted successfully:", response.data);
        setMessage("Review submitted successfully! ✅");

        // Reset form
        setFormData({
          name: "",
          email: "",
          phone: "",
          review: "",
          image: null,
        });

        // Trigger callback to refresh testimonials
        if (onSubmit) {
          onSubmit();
        }
      } else {
        throw new Error("Unexpected response status.");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error submitting review. Please try again. ❌";
      console.error("❌ Error submitting review:", errorMessage);
      setMessage(errorMessage);
    }

    setLoading(false);
  };

  return (
    <div className="review-form-container">
      <h2>Leave a Review</h2>
      {message && <p className="message">{message}</p>}
      <form className="review-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Review</label>
          <textarea
            name="review"
            value={formData.review}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label>Upload Image</label>
          <input type="file" accept="image/*" onChange={handleImageChange} />
        </div>
        <button type="submit" className="submit-btn" disabled={loading}>
          {loading ? "Submitting..." : "Submit Review"}
        </button>
      </form>
    </div>
  );
};

export default ReviewForm;
