import React, { useState } from "react";
import "./floatingcontact.scss";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const FloatingContact = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`floating-contact ${isOpen ? "open" : ""}`}>
      <div className="contact-button" onClick={() => setIsOpen(!isOpen)}>
        <i className="fas fa-comments"></i>
      </div>
      <div className="contact-options">
        <a
          href="https://wa.me/+919415984124?text=Hello,%20I%20am%20interested%20in%20purchasing%20a%20Banaras%20Gulabi%20Meenakari%20product.%20Can%20you%20share%20the%20details?"
          className="whatsapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-whatsapp"></i>
        </a>
        <a
          href="https://www.instagram.com/banaras_gulabi_meenakari"
          className="instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>
        <a
          href="https://maps.app.goo.gl/3VtvJjHiGZfsAigR7"
          className="map"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-map-marker-alt"></i>
        </a>
        <a href="tel:+919415984124" className="call">
          <i className="fas fa-phone"></i>
        </a>
      </div>
    </div>
  );
};

export default FloatingContact;
