import React, { useEffect, useState } from "react";
import axios from "axios";
import "./testimonials.scss";

const Testimonials = ({ refreshTrigger }) => {
  const [reviews, setReviews] = useState([]);

  const fetchReviews = async () => {
    try {
      const response = await axios.get(
        "https://meenakari-backend.onrender.com/api/reviews"
      );
      setReviews(response.data);
    } catch (error) {
      console.error("❌ Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, [refreshTrigger]); // Refresh when `refreshTrigger` changes

  return (
    <section id="testimonial" className="testimonials">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#fff"
          fillOpacity="1"
          d="M0,224L24,192C48,160,96,96,144,96C192,96,240,160,288,202.7C336,245,384,267,432,266.7C480,267,528,245,576,229.3C624,213,672,203,720,181.3C768,160,816,128,864,133.3C912,139,960,181,1008,197.3C1056,213,1104,203,1152,176C1200,149,1248,107,1296,101.3C1344,96,1392,128,1416,144L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"
        ></path>
      </svg>

      <div className="container">
        <div className="row text-center text-white">
          <h1 className="display-3 fw-bold">Testimonials</h1>
          <p className="lead pt-1">What our purchasers are saying</p>
          <hr className="mx-auto" />
        </div>

        {/* Start Carousel */}
        <div className="row align-items-center">
          <div id="carouselExampleCaptions" className="carousel slide">
            <div className="carousel-inner">
              {reviews.length > 0 ? (
                reviews.map((review, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <div className="row testimonials__container justify-content-around">
                      <div className="testimonials__picture col-sm-4">
                        <img
                          src={review.image || "logo3.png"}
                          alt={review.name}
                          className="testimonials__image"
                        />
                      </div>
                      <div className="testimonials__card col-sm-7">
                        <p className="lh-lg">
                          <i className="fas fa-quote-left"></i>
                          {review.review}
                          <i className="fas fa-quote-right"></i>
                        </p>
                        <div className="ratings">
                          {[...Array(5)].map((_, i) => (
                            <i key={i} className="fas fa-star"></i>
                          ))}
                        </div>
                        <div className="testimonials__name">
                          <h3>{review.name}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-white text-center">
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <p className="mt-2">Loading reviews. Please wait.</p>
                </div>
              )}
            </div>

            {/* Carousel Buttons */}
            <div className="text-center pt-2">
              <button
                className="btn btn-outline-light fas fa-long-arrow-alt-left"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
              ></button>
              <button
                className="btn btn-outline-light fas fa-long-arrow-alt-right"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
              ></button>
            </div>
          </div>
        </div>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#fff"
          fillOpacity="1"
          d="M0,224L24,192C48,160,96,96,144,96C192,96,240,160,288,202.7C336,245,384,267,432,266.7C480,267,528,245,576,229.3C624,213,672,203,720,181.3C768,160,816,128,864,133.3C912,139,960,181,1008,197.3C1056,213,1104,203,1152,176C1200,149,1248,107,1296,101.3C1344,96,1392,128,1416,144L1440,160L1440,320L1416,320C1392,320,1344,320,1296,320C1248,320,1200,320,1152,320C1104,320,1056,320,1008,320C960,320,912,320,864,320C816,320,768,320,720,320C672,320,624,320,576,320C528,320,480,320,432,320C384,320,336,320,288,320C240,320,192,320,144,320C96,320,48,320,24,320L0,320Z"
        ></path>
      </svg>
    </section>
  );
};

export default Testimonials;
