import React from "react";
import "./portfolio.scss";
const Portfolio = () => {
  return (
    <section id="portfolio" className="portfolio">
      <div className="container">
        <div className="row text-center mt-5">
          <h1 className="display-3 fw-bold">Latest Works</h1>
          <div className="heading-line"></div>
          <p className="lead mt-3">
            We proudly present our latest collection, featuring the most
            cutting-edge products that embody innovation, style, and
            functionality. Our team has painstakingly curated a selection that
            represents the pinnacle of craftsmanship and design. While we
            showcase a tantalizing glimpse of our offerings here, we
            wholeheartedly invite you to experience the full breadth and depth
            of our inventory at our esteemed store. There, you will be greeted
            by a treasure trove of even more exceptional products awaiting your
            discovery. In our physical space, you will be able to indulge your
            senses, engage with our knowledgeable staff, and immerse yourself in
            the tangible essence of our brand. The opportunity to touch, feel,
            and interact with our diverse range of merchandise will undoubtedly
            enrich your shopping experience and enable you to make a truly
            informed choice.
          </p>
        </div>
        {/* Section BUTTON */}
        <div className="row mt-5 mb-4 g-3 text-center ">
          <div className="col-md-12">
            <button className="btn btn-outline-primary" type="button">
              Gulabi Meenakari Idols
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 items-container">
            <div className="portfolio-box shadow">
              <img
                src={require(`../../assets/images/productsNew/idol2.jpeg`)}
                alt=""
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption m-2">
                  <h4>
                    Nandi ji | Meenakari | Silverbased | Handicraft | GI product
                  </h4>
                  <p>
                    Nandi, also known as Nandin or Nandikeshvara, is the sacred
                    bull and devoted vehicle (vahana) of Lord Shiva. He is a
                    symbol of loyalty, strength, and devotion, which artists
                    often use to convey powerful messages in their works.
                  </p>
                  <a href="https://wa.me/+919415984124?text=Hello,%20I%20am%20interested%20in%20purchasing%20a%20Banaras%20Gulabi%20Meenakari%20Nandi%20Ji%20idol.%20Can%20you%20share%20the%20details?">
                    <button
                      type="button"
                      className="rounded-pill btn-rounded-sm"
                    >
                      Buy Now
                      <span>
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 items-container">
            <div className="portfolio-box shadow">
              <img
                src={require(`../../assets/images/productsNew/idol5.jpeg`)}
                alt=""
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption m-2">
                  <h4>
                    Dancing Peacock | Meenakari | Silverbased | Handicraft | GI
                    product
                  </h4>
                  <p>
                    A magnificent and colorful bird native to the Indian
                    subcontinent.Its vibrant colors and intricate patterns have
                    influenced various art forms, from paintings and sculptures
                    to fashion and interior design.
                  </p>
                  <a href="https://wa.me/+919415984124?text=Hello,%20I%20am%20interested%20in%20purchasing%20a%20Banaras%20Gulabi%20Meenakari%20Dancing%20Peacock%20idol.%20Can%20you%20share%20the%20details?">
                    <button
                      type="button"
                      className="rounded-pill btn-rounded-sm"
                    >
                      Buy Now
                      <span>
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 items-container">
            <div className="portfolio-box shadow">
              <img
                src={require(`../../assets/images/productsNew/idol4.jpeg`)}
                alt=""
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption m-2">
                  <h4>
                    Nariyal Ganesh (small and big) | Meenakari | Silverbased |
                    Handicraft | GI product
                  </h4>
                  <p>
                    In this form of worship, the coconut symbolizes the deity's
                    head, and it is adorned with decorations and markings to
                    represent Lord Ganesha and giving the idol a unique and
                    artistic touch.
                  </p>
                  <a href="https://wa.me/+919415984124?text=Hello,%20I%20am%20interested%20in%20purchasing%20a%20Banaras%20Gulabi%20Meenakari%20Nariyal%20Ganesh%20idol.%20Can%20you%20share%20the%20details?">
                    <button
                      type="button"
                      className="rounded-pill btn-rounded-sm"
                    >
                      Buy Now
                      <span>
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 items-container">
            <div className="portfolio-box shadow">
              <img
                src={require(`../../assets/images/productsNew/idol3.jpeg`)}
                alt=""
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption m-2">
                  <h4>
                    Dhundhraj Ganesh | Meenakari | Handicraft | Silverbased | GI
                    product
                  </h4>
                  <p>
                    Dhundhiraj Vinayak form of Shri Ganesha is the part of Ashta
                    Pradhan Vinayak and also prominent in Chhappan(56) Vinayak
                    are those of Shri Ganesh.
                  </p>
                  <a href="https://wa.me/+919415984124?text=Hello,%20I%20am%20interested%20in%20purchasing%20a%20Banaras%20Gulabi%20Meenakari%20Dhundhraj%20Ganesh%20idol.%20Can%20you%20share%20the%20details?">
                    <button
                      type="button"
                      className="rounded-pill btn-rounded-sm"
                    >
                      Buy Now
                      <span>
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 items-container">
            <div className="portfolio-box shadow">
              <img
                src={require(`../../assets/images/productsNew/idol1.jpeg`)}
                alt=""
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption m-2">
                  <h4>
                    Dhapli Ganesh | Meenakari | Silverbased | Handicraft | GI
                    product
                  </h4>
                  <p>
                    The significance of Dhapli Ganesh lies in the representation
                    of Lord Ganesha's association with music, rhythm, and arts.
                    The dhapli or tambourine is an instrument often associated
                    with joyous celebrations, devotional music, and dance.
                  </p>
                  <a href="https://wa.me/+919415984124?text=Hello,%20I%20am%20interested%20in%20purchasing%20a%20Banaras%20Gulabi%20Meenakari%20Dhapli%20Ganesh%20idol.%20Can%20you%20share%20the%20details?">
                    <button
                      type="button"
                      className="rounded-pill btn-rounded-sm"
                    >
                      Buy Now
                      <span>
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-4 g-3 text-center ">
          <div className="col-md-12">
            <button className="btn btn-outline-primary" type="button">
              Gulabi Meenakari Earings
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 items-container">
            <div className="portfolio-box shadow">
              <img
                src={require(`../../assets/images/productsNew/earing1.jpeg`)}
                alt=""
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption m-2">
                  <h4>
                    Gulabi Meenakari Tops Meenakari | Silverbased | Handicraft |
                    gi product
                  </h4>
                  <p>
                    Banaras Gulabi Meenakari is not just limited to traditional
                    jewellery but expands into more modern products including
                    exquisite ornaments, earrings, Traditional boxes, figures of
                    deities and decorative showpieces etc. The colours used in
                    the gulabi meenakari are natural and leadless dyes.🍁
                  </p>
                  <a href="https://wa.me/+919415984124?text=Hello,%20I%20am%20interested%20in%20purchasing%20a%20Banaras%20Gulabi%20Meenakari%20Earrings.%20Can%20you%20share%20the%20details?">
                    <button
                      type="button"
                      className="rounded-pill btn-rounded-sm"
                    >
                      Buy Now
                      <span>
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 items-container">
            <div className="portfolio-box shadow">
              <img
                src={require(`../../assets/images/productsNew/earing2.jpeg`)}
                alt=""
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption m-2">
                  <h4>
                    Gulabi Meenakari Jhumi Small Meenakari | Silverbased |
                    Handicraft | GI product
                  </h4>
                  <p>
                    Banaras Gulabi Meenakari is not just limited to traditional
                    jewellery but expands into more modern products including
                    exquisite ornaments, earrings, Traditional boxes, figures of
                    deities and decorative showpieces etc. The colours used in
                    the gulabi meenakari are natural and leadless dyes.🍁
                  </p>
                  <a href="https://wa.me/+919415984124?text=Hello,%20I%20am%20interested%20in%20purchasing%20a%20Banaras%20Gulabi%20Meenakari%20Earrings.%20Can%20you%20share%20the%20details?">
                    <button
                      type="button"
                      className="rounded-pill btn-rounded-sm"
                    >
                      Buy Now
                      <span>
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 items-container">
            <div className="portfolio-box shadow">
              <img
                src={require(`../../assets/images/productsNew/earing3.jpeg`)}
                alt=""
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption m-2">
                  <h4>
                    Gulabi Meenakari Leaf Stud Meenakari | Silverbased |
                    Handicraft | GI product
                  </h4>
                  <p>
                    Banaras Gulabi Meenakari is not just limited to traditional
                    jewellery but expands into more modern products including
                    exquisite ornaments, earrings, Traditional boxes, figures of
                    deities and decorative showpieces etc. The colours used in
                    the gulabi meenakari are natural and leadless dyes.🍁
                  </p>
                  <a href="https://wa.me/+919415984124?text=Hello,%20I%20am%20interested%20in%20purchasing%20a%20Banaras%20Gulabi%20Meenakari%20Earrings.%20Can%20you%20share%20the%20details?">
                    <button
                      type="button"
                      className="rounded-pill btn-rounded-sm"
                    >
                      Buy Now
                      <span>
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 items-container">
            <div className="portfolio-box shadow">
              <img
                src={require(`../../assets/images/productsNew/earing4.jpeg`)}
                alt=""
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption m-2">
                  <h4>
                    Gulabi Meenakari Earing Meenakari | Silverbased | Handicraft
                    | GI product
                  </h4>
                  <p>
                    Banaras Gulabi Meenakari is not just limited to traditional
                    jewellery but expands into more modern products including
                    exquisite ornaments, earrings, Traditional boxes, figures of
                    deities and decorative showpieces etc. The colours used in
                    the gulabi meenakari are natural and leadless dyes.🍁
                  </p>
                  <a href="https://wa.me/+919415984124?text=Hello,%20I%20am%20interested%20in%20purchasing%20a%20Banaras%20Gulabi%20Meenakari%20Earrings.%20Can%20you%20share%20the%20details?">
                    <button
                      type="button"
                      className="rounded-pill btn-rounded-sm"
                    >
                      Buy Now
                      <span>
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 items-container">
            <div className="portfolio-box shadow">
              <img
                src={require(`../../assets/images/productsNew/earing5.jpeg`)}
                alt=""
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption m-2">
                  <h4>
                    Gulabi Meenakari Stud Meenakari | Silverbased | Handicraft |
                    GI product
                  </h4>
                  <p>
                    Banaras Gulabi Meenakari is not just limited to traditional
                    jewellery but expands into more modern products including
                    exquisite ornaments, earrings, Traditional boxes, figures of
                    deities and decorative showpieces etc. The colours used in
                    the gulabi meenakari are natural and leadless dyes.🍁
                  </p>
                  <a href="https://wa.me/+919415984124?text=Hello,%20I%20am%20interested%20in%20purchasing%20a%20Banaras%20Gulabi%20Meenakari%20Earrings.%20Can%20you%20share%20the%20details?">
                    <button
                      type="button"
                      className="rounded-pill btn-rounded-sm"
                    >
                      Buy Now
                      <span>
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-2">
          <div className="row pt-4 text-center">
            <h1 className="display-3 fw-bold">Raksha Bandhan Specials</h1>
            <div className="heading-line mb-5"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 items-container">
            <div className="portfolio-box shadow">
              <img
                src={require(`../../assets/images/productsNew/rakhi1.jpg`)}
                alt=""
                className="img-fluid rakhi"
              />
              <div className="portfolio-info">
                <div className="caption m-2">
                  <h4>
                    Gulabi Meenakari Rakhi 🕉️ | Silverbased | Handicraft | gi
                    product
                  </h4>
                  <p>
                    Embrace the essence of Banaras with our Pink Meenakari
                    Rakhi, showcasing the timeless art of Gulabi Meenakari.The
                    vibrant hues adorning the rakhi are derived from natural,
                    lead-free dyes, adding a touch of nature's beauty to your
                    celebrations. 🌸
                  </p>
                  <a href="https://wa.me/+919415984124?text=Hello,%20I%20am%20interested%20in%20purchasing%20a%20Banaras%20Gulabi%20Meenakari%20Rakhi.%20Can%20you%20share%20the%20details?">
                    <button
                      type="button"
                      className="rounded-pill btn-rounded-sm"
                    >
                      Buy Now
                      <span>
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 items-container">
            <div className="portfolio-box shadow">
              <img
                src={require(`../../assets/images/productsNew/rakhi2.jpg`)}
                alt=""
                className="img-fluid rakhi"
              />
              <div className="portfolio-info">
                <div className="caption m-2">
                  <h4>
                    Gulabi Meenakari Rakhi Ganesha | Silverbased | Handicraft |
                    gi product
                  </h4>
                  <p>
                    Pink Meenakari Rakhi reveals timeless Gulabi Meenakari
                    artistry. Vibrant hues, nature-derived dyes enhance
                    celebratory beauty.The vibrant hues adorning the rakhi are
                    derived from natural, lead-free dyes, adding a touch of
                    nature's beauty to your celebrations. 🌸
                  </p>
                  <a href="https://wa.me/+919415984124?text=Hello,%20I%20am%20interested%20in%20purchasing%20a%20Banaras%20Gulabi%20Meenakari%20Rakhi.%20Can%20you%20share%20the%20details?">
                    <button
                      type="button"
                      className="rounded-pill btn-rounded-sm"
                    >
                      Buy Now
                      <span>
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
